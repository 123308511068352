import { component } from "vue/types/umd";

/**
 * Add application routes here
 */
export default [
  {
    path: '/',
    component: () => import('modules/core/components/App.vue' as string),
    redirect: '/dashboard',
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: () => import('modules/dashboard/components/Dashboard.vue' as string),
      },
      {
        name: 'clients',
        path: '/clients',
        component: () => import('modules/clients/components/Clients.vue' as string),
        children: [
          {
            name: 'clients-all',
            path: '/clients',
            component: () => import('modules/clients/components/All.vue' as string),
            props: true,
          },
          {
            name: 'clients-in-progress',
            path: '/clients/in-progress',
            component: () =>
              import('modules/clients/components/InProgress.vue' as string),
          },
          {
            name: 'clients-completed',
            path: '/clients/completed',
            component: () => import('modules/clients/components/Completed.vue' as string),
            props: true,
          },
        ],
      },
      {
        name: 'client-details',
        path: '/client/:id',
        component: () =>
          import('modules/clients/components/ClientDetails.vue' as string),
      },
      {
        name: 'AllMessages',
        path: '/messages',
        component: () => import('modules/messages/components/Messages.vue' as string),
        props: true,
      },
      {
        name: 'messages',
        path: '/messages/:id?',
        component: () => import('modules/messages/components/Messages.vue' as string),
      },
      {
        name: 'chat',
        path: '/chat/:id',
        component: () => import('modules/messages/components/chat/Chat.vue' as string),
      },
      {
        name: 'mortgage',
        path: '/mortgage/:id',
        component: () => import('modules/mortgages/components/Mortgage.vue' as string),
      },
      {
        name: 'SendNotification',
        path: '/send-notification/:id',
        component: () => import('modules/mortgages/components/views/Notification.vue' as string),
      },
      {
        name: 'SendBulkNotification',
        path: '/send-bulk-notification',
        component: () => import('modules/clients/components/SendBulkNotification.vue' as string),
        props: true,
      },
      {
        name: 'brokers',
        path: '/brokers',
        component: () => import('modules/brokers/components/Brokers.vue' as string),
        meta: { requiresBrokerRole: true },
      },
      {
        name: 'administrators',
        path: '/administrators',
        component: () => import('modules/personalAssistant/components/PersonalAssistants.vue' as string),
      },
      {
        name: 'broker-details',
        path: '/broker/:id',
        component: () => import('modules/brokers/components/BrokerDetails.vue' as string),
        meta: { requiresBrokerRole: true },
      },
      {
        name: 'profile',
        path: '/profile',
        component: () => import('modules/profile/components/Profile.vue' as string),
      },
      {
        name: 'settings',
        path: '/settings',
        component: () => import('modules/settings/components/Settings.vue' as string),
      },
      {
        name: 'create-client',
        path: '/create-client',
        component: () =>
          import('modules/create_client/components/CreateClient.vue' as string),
      },
      {
        name: 'create-super-user',
        path: '/create-super-user',
        component: () =>
          import('modules/create_super_user/components/CreateSuperUser.vue' as string),
      },
      {
        name: 'create-administrator',
        path: '/create-administrator',
        component: () =>
          import('modules/personalAssistant/components/CreatePersonalAssistant.vue' as string),
      },
      {
        name: 'notifications',
        path: '/notifications',
        component: () =>
          import('modules/notifications/components/AllNotifications.vue' as string),
      },
      {
        name: 'referrals',
        path: '/referrals',
        component: () =>
          import('modules/referrals/components/AllReferrals.vue' as string),
        props: true,
      },
      {
        name: 'create-mortgage',
        path: '/create-mortgage/:clientId',
        component: () => import('modules/mortgages/components/CreateMortgage.vue' as string),
      },
      {
        name: 'notification-view',
        path: '/notification/:id',
        component: () =>
          import('modules/notifications/components/AllNotifications.vue' as string),
      },
      {
        name: 'leads',
        path: '/leads',
        component: () => import('modules/leads/components/Leads.vue' as string),
      },
      {
        name: 'superusers',
        path: '/superusers',
        component: () => import('modules/superusers/components/SuperUsers.vue' as string),
      },
      {
        name: 'company',
        path: '/company',
        component: () => import('modules/company/components/Company.vue' as string),
      },
    ],
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('modules/auth/components/Login.vue' as string),
  },
  {
    name: 'select-broker',
    path: '/select-broker',
    component: () => import('modules/core/components/SelectBroker.vue' as string),
  },
  {
    name: 'select-broker-owner',
    path: '/select-broker-owner',
    component: () => import('modules/core/components/SelectBrokerOwner.vue' as string),
  },
  {
    name: 'logout',
    path: '/logout',
    component: () => import('modules/auth/components/Logout.vue' as string),
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: () => import('modules/auth/components/ResetPassword.vue' as string),
  },
  {
    name: 'set-new-password',
    path: '/reset-password/:token',
    component: () => import('modules/auth/components/SetNewPassword.vue' as string),
  },
  // Always leave this last one
  {
    path: '*',
    component: () => import('modules/auth/components/Login.vue' as string),
  }, // Not found
];
